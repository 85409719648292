// firebase.js
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import 'firebase/compat/performance';

const configOptions = {
  apiKey: 'AIzaSyBTROuJ3i0eEeETK0_kQN4HQp7PHkGudu4',
  authDomain: "eventos-do-76766.firebaseapp.com",
  databaseURL: "https://eventos-do-76766.firebaseio.com",
  projectId: "eventos-do-76766",
  storageBucket: "eventos-do-76766.appspot.com",
  messagingSenderId: "275689703844",
  appId: '1:275689703844:web:1314ca3de7386946',
  measurementId: "G-HMHE82L7V3"
};

firebase.initializeApp(configOptions);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
firebase.analytics();
firebase.performance();

export default firebase;
